import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Team2 extends Component {
    render() {
        return (
            <div className="col-12 col-md-12 col-lg-8">
            <div className="row align-items-center">
              <div className="col-12 col-lg-6 col-md-6 mb-8 mb-lg-0">

                <div className="p-3 d-flex align-items-center shadow mt-8">
                  <div className="mr-5">
                    <img className="img-fluid rounded-top" src={require(`../../assets/images/team/Sameer.png`)} width={400} />
                  </div>
                  <div>
                    <small className="text-primary mb-1 d-block">CTO</small>
                    <h5 className="mb-2">Sameer Qureshi</h5>
                    <p>Sameer is living his life-long dream as a software engineer and his peers jokingly refer to him as a human code compiler.</p>
                  </div>
                </div>
                <div className="p-3 d-flex align-items-center shadow">
                  <div className="mr-5">
                    <img className="img-fluid rounded-top" src={ require(`../../assets/images/team/Zeeshan.png`) } width={400} />
                  </div>
                  <div>
                    <small className="text-primary mb-1 d-block">Development Team Lead</small>
                    <h5 className="mb-2">Zeeshan Ahmed</h5>
                    <p>Zeeshan is an incredible developer whose team admires him for his efficient code and beautiful intuitive designs</p>

                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6 col-md-6 mb-8 mb-lg-0">
                <div className="text-center shadow p-5">
                  <div className="mb-3">
                    <img className="img-fluid rounded-top" src={require(`../../assets/images/team/Jeremy.png`)} width={200} />
                  </div>
                  <div>
                    <small className="text-primary mb-1 d-block">CEO</small>
                    <h5 className="mb-2">Jeremy Jones</h5>
                    <p>Former Emergency Room RN turned programmer and serial entrepreneur. Jeremy found his passion for programming as a second career.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
       
        );
    }
}

export default Team2;