import React, {Component} from 'react';
import navLinks from '../../api/NavLinks';
import {Link} from 'react-router-dom';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavbarText
} from 'reactstrap'


class Header3 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            visible: false,
            loader: true,
            headername: window.location.pathname
        }
        this.toggle = this.toggle.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    handleClick(event) {
        var elems = document.querySelectorAll(".childsubmenu");
        [].forEach.call(elems, function (el) {
            el.classList.remove("show");
        });
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll() {

        var scrollTop = (document.documentElement && document.documentElement.scrollTop) ||
            document.body.scrollTop;
        if (scrollTop > 100) {
            this.setState({
                visible: true
            });
        } else {
            this.setState({
                visible: false
            });
        }

    }

    render() {
        const {visible, headername} = this.state;
        console.log('okay', headername)
        if (this.state.loader == true) {
            setTimeout(function () {
                this.setState({loader: false});
            }.bind(this), 2000);
        }
        return (
            <header
                className={` ${(headername == "/index2") ? "site-header  bg-dark navbar-dark" : "site-header  navbar-dark"}`}>
                {(this.state.loader == false) ?
                    <div id="header-wrap"
                         className={` ${(headername == "/index2") ? "" : "position-absolute w-100 z-index-1"} ${(visible) ? "fixed-header " : ""}`}>
                        <div className="container">
                            <div className="row">
                                {/*menu start*/}
                                <div className="col d-flex align-items-center justify-content-between">
                                    <img className="img-fluid"
                                         src={require(`../../assets/images/client/Staff Genix logo-01.png`)} alt="" width={250} height={100}/>
                                    <a className="btn btn-light ml-8 d-none d-lg-block" href="tel:+18337823349">1 (833) STAFF-GX</a>
                                </div>
                                {/*menu end*/}
                            </div>
                        </div>
                    </div>
                    :
                    <div id="ht-preloader">
                        <div className="loader clear-loader">
                            <img src={require(`../../assets/images/client/Staff-Genix-logo-transparent-long.png`)} className="img-fluid" alt="..." />
                        </div>
                    </div>
                }
            </header>

        );
    }
}

export default Header3;