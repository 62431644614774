import React, { Component } from 'react';

class BrandLogo extends Component {
    render() {
        return (

            <div>
                <div className="row">
                    <div className="col">
                        <div className="clients-logo">
                            <img className="img-fluid" src={require(`../../assets/images/client/MSH.png`)} alt="" />
                        </div>
                    </div>
                    <div className="col">
                        <div className="clients-logo">
                            <img className="img-fluid" src={require(`../../assets/images/client/TLC.svg`)} alt="" />
                        </div>
                    </div>
                    <div className="col">
                        <div className="clients-logo">
                            <img className="img-fluid" src={require(`../../assets/images/client/LHS.svg`)} alt="" />
                        </div>
                    </div>
                    <div className="col">
                        <div className="clients-logo">
                            <img className="img-fluid" src={require(`../../assets/images/client/RAD.svg`)} alt="" />
                        </div>
                    </div>
                    <div className="col">
                        <div className="clients-logo">
                            <img className="img-fluid" src={require(`../../assets/images/client/N2N.svg`)} alt="" />
                        </div>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col">
                        <div className="clients-logo">
                            <img className="img-fluid" src={require(`../../assets/images/client/MYN.svg`)} alt="" />
                        </div>
                    </div>

                </div>
            </div>


        );
    }
}

export default BrandLogo;