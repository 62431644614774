import React, { Component } from 'react';
import Clientlogo from "../common/clientlogo";
import {Link} from "react-router-dom";

class Herosection4 extends Component {
    render() {
        return (
            <section className="custom-pt-1 custom-pb-2 bg-primary position-relative">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-12 col-lg-5 col-xl-6 order-lg-2 mb-8 mb-lg-0">
                  {/* Image */}
                  <img src={require(`../../assets/images/hero/08.png`)} className="img-fluid" alt="..." />
                </div>
                <div className="col-12 col-lg-7 col-xl-6 order-lg-1">
                  {/* Heading */}
                  <h5 className="badge badge-light">Genius staffing software solutions</h5>
                  <h1 className="display-4 text-white mt-3">
                   Creators of the next generation healthcare staffing platform
                      </h1>
                  {/* Text */}
                  <p className="lead text-light">We are using the latest technologies to create streamlined systems that are facilitating greater efficiency and client focus for our agency partners.</p>

                </div>

              </div>

              {/* / .row */}
                <div className="item">
                    <a className="btn btn-primary shadow mr-1" href="https://ats.staffgenix.com" target={`_blank`}>Recruiter Login</a>
                    <a className="btn btn-outline-primary" href="https://division.staffgenix.com" target={`_blank`}>Manager Login</a>
                </div>
            </div>

            {/* / .container */}
          </section>
         
        );
    }
}

export default Herosection4;